import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-rain-or-shine/"
            }}>{`Rain or Shine`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-straight-four/"
            }}>{`Straight Four`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: volatility-targeting strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks, bonds, commodities, VIX futures`}</li>
        <li parentName="ul">{`Rebalancing schedule: daily`}</li>
        <li parentName="ul">{`Taxation: 50% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $10,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`VIX Spritz`}</em>{` aims to create returns similar to the balanced 60/40 benchmark while reducing drawdowns. The proprietary strategy tilts its asset allocation of stocks, U.S. treasuries, gold, and commodities based on VIX readings. In times of market turmoil, `}<em parentName="p">{`VIX Spritz`}</em>{` adds exposure to VIX futures, effectively hedging the portfolio against sudden increases in volatility associated with rapid market selloffs. The strategy has very moderate maintenance requirements: While it calls for daily rebalancing, this task typically only affects two ETFs, making it straightforward. In summary, `}<em parentName="p">{`VIX Spritz`}</em>{` appeals to investors seeking balanced growth and a complement to momentum-based strategies.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Equal Voltage: A volatility-targeting strategy",
                "title": "TuringTrader.com, Equal Voltage: A volatility-targeting strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/volatility-targeting/"
            }}>{`Volatility Targeting`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`VIX Spritz`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`start with a static allocation of 5% gold, 5% commodities, 5% TIPS, 20% U.S. treasuries and 15% S&P 500`}</li>
        <li parentName="ul">{`allocate up to 10% in VIX futures, based on a VIX forecasting model`}</li>
        <li parentName="ul">{`allocate up to an additional 40% in U.S. treasuries, based on a VIX forecasting model`}</li>
        <li parentName="ul">{`allocate the remaining capital to the S&P 500`}</li>
      </ul>
      <p>{`The strategy is based on the expectation that U.S. stocks generally provide excellent growth opportunities. Volatility poses the biggest threat to this growth and is generally negatively correlated, resulting in short exposure to volatility. `}<em parentName="p">{`VIX Spritz`}</em>{` compensates this by taking on a relatively small long position of the VIX through an ETF tracking VIX futures.`}</p>
      <p>{`To learn more about the rationale behind `}<em parentName="p">{`VIX Spritz`}</em>{`, please read our article regarding volatility targeting.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`VIX Spritz`}</em>{` delivers returns matching its benchmark in most years but really shines in tumultuous times. The static asset allocation, including treasuries, TIPS, gold, and commodities, provides some level of protection against unforeseen events. The active management with a daily rebalancing schedule allows swift reactions to fluctuating market conditions. Especially the additional positive exposure to the VIX helps prevent deep drawdowns.`}</p>
      <p>{`As a result, `}<em parentName="p">{`VIX Spritz`}</em>{` shows at least a 30% lower risk than a passive 60/40 over the observed period. The Monte-Carlo simulation confirms these results: The portfolio provides a massive upside with much lower drawdowns.`}</p>
      <p>{`In summary, `}<em parentName="p">{`VIX Spritz`}</em>{` beats a 60/40 in most years. Over the full economic cycle, the strategy even beats the S&P 500 by a wide margin.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`VIX Spritz`}</em>{` is well diversified across multiple asset classes, which is confirmed by the strategy's very low beta. Even in the most bullish periods, exposure to the S&P 500 never exceeds 65%, which is quite conservative, especially given the strategy's high returns.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`VIX Spritz`}</em>{` trades frequently and regularly triggers taxable events. However, the tax burden is lower than it might appear at first. 50% of the assets in the portfolio are buy-and-hold. While the remaining 50% are actively traded, there are many periods where a significant part of the stock allocation is held for periods exceeding 12 months, qualifying for long-term treatment of capital gains. While the overall tax burden is certainly higher than for a passive 60/40, the substantially higher long-term returns should outweigh this disadvantage.`}</p>
      <p><em parentName="p">{`VIX Spritz`}</em>{` makes use of an ETF linked to volatility futures, which is considered a high-risk instrument. Most brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts. Because we use this ETF only sporadically to counteract the portfolio's inherent inverse volatility exposure, we believe our use of this ETF to be responsible.`}</p>
      <p><em parentName="p">{`VIX Spritz`}</em>{` invests in up to six ETFs at a time. The strategy should function as intended with as little as $10,000 of capital.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, May 2020`}</a>{`: Initial release.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, April 2022`}</a>{`: Updated strategy for improved behavior in environments of rising rates. Replaced VXX with VIXY.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      